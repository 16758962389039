import { authApiUrl } from '@crystal-eyes/config'
import { trackExecution } from '@crystal-eyes/utils/instrumentation'
import logger from '@crystal-eyes/utils/logger'

export const AUTH_TOKEN_REVALIDATE_SECS = 60 * 45

export function getApiUrl(path: string) {
  return `${authApiUrl}/${path}`
}

export function fetchAuthApi(path: string, options: any = {}) {
  const url = getApiUrl(path)

  return trackExecution<Response>(`Auth API: ${path}`, () => {
    return fetch(url, {
      credentials: 'include',
      ...options,
      headers: {
        ...(options.headers || {}),
        'Content-Type': 'application/json',
      },
    }).then(async resp => {
      if (resp.status > 399)
        return Promise.reject({ status: resp.status, body: await resp.json() })
      return resp
    })
  })
}

export async function getSSODetails(opts: {
  email: string
  redirectUri?: string
}) {
  const { email, redirectUri } = opts

  const params: { [key: string]: string } = { email: email }
  if (redirectUri) params.redirect_uri = redirectUri

  return fetchAuthApi('tokens/sso?' + new URLSearchParams(params))
    .then(resp => resp.json())
    .catch(err => {
      logger.error('Fetch SSO details', err)
    })
}

export async function getAuthToken(opts: { refresh?: string } = {}) {
  const body = opts.refresh ? { refresh: opts.refresh } : ''

  const authToken = await fetchAuthApi('tokens', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify(body),
    next: {
      revalidate: AUTH_TOKEN_REVALIDATE_SECS,
      tags: ['auth', 'auth-token'],
    },
  })
    .then(resp => resp.json())
    .then(data => data.token)

  return authToken
}
